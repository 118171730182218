.root {
    position: fixed;
    height: 100vh;
    width: 100vw;
}

.inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.card {
    min-width: 300px;
}