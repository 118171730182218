.item {
    text-decoration: none;
    color: var(--mangine-color-text);
    background-color: var(--mantine-color-body);
    outline: none;
    //border: solid 1px var(--mantine-color-blue-outline);
    border: none;
    cursor: pointer;
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);

    &:hover {
        background-color: var(--mantine-color-blue-light-hover);   
    }

    border-bottom: 1px solid var(--mantine-color-default-border);
}

.active {
    background-color: var(--mantine-color-blue-light);
}